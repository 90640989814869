<style>
.tarifas_main_container {
    margin-top: 32px;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-image: url(../assets/img/Simple\ Shiny.svg);
    background-repeat: no-repeat;
}

.form_container {
    position: relative;
    width: 400px;
    margin: 28px 0;
    background-color: #eee;
    padding: 8px;
    border-radius: 8px;
    

}
.form_container h2{
    color: #000000;
}
/* Estilos para el input y el menú desplegable */
.autocomplete {
    position: relative;
    display: inline-block;
}

.autocomplete-items {
    position: absolute;
    border: 1px solid #d4d4d4;
    border-bottom: none;
    border-top: none;
    z-index: 99;
    top: 100%;
    left: 0;
    right: 0;
}

.autocomplete-items div {
    padding: 10px;
    cursor: pointer;
    background-color: #fff;
    border-bottom: 1px solid #d4d4d4;
}

.autocomplete-items div:hover {
    background-color: #e9e9e9;
}

.autocomplete-active {
    background-color: DodgerBlue !important;
    color: #ffffff;
}
.form_form{
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 6px;
}
#autocomplete-list{
    
    border-radius: 8px;
    padding: 8px;
    font-family: "Quicksand", sans-serif;
    color: #515151;
    position: absolute;
    background: white;
    width: 100%;
    top: 40px;
    /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.contender_de_paises{
    text-wrap: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 4px 0;
    border-bottom: 1px solid hsl(0, 0%, 32%, 0.5);
    cursor: pointer;
}
.load-4 .ring-1 {
  animation: loadingD 1.5s 0.3s cubic-bezier(0.17, 0.37, 0.43, 0.67) infinite;
}
.ring-1 {
  width: 50px;
  height: 50px;
  margin: 0 auto;
  padding: 10px;
  border: 7px dashed #4b9cdb;
  border-radius: 100%;
}
@keyframes loadingD {
  0%{
    transform: rotate(0deg);
  }
   50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }

  
}

.bg-img-tarifas{
    width: 100%;
    background-image: url(../../public/img/5cli.png);
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 460px;
    display: flex;
    align-items: center;
    justify-content: center;

}
.main_container_tarifas{
    display: flex;
    gap: 16px;
    margin: 28px 0;
}
.titulos_tarifas{
    margin: 28px 0;
    align-self: center;
    text-align: start;
    max-width: 500px;
}
.titulos_tarifas h3{
    color: #000000;
}
@media (max-width: 993px) {
.main_container_tarifas{
    flex-direction: column;
    align-items: center;
    gap: 0;
    width: 100%;
    margin: 0;
}
.titulos_tarifas{
    margin: 28px 0;
    align-self: center;
    text-align: center;
    max-width: 90%;
}
}
@media (max-width: 420px) {
    .form_container{
        width: 100%;
        border-radius: 0;
    }
    .texto_precios_estimado{
        display: flex;
    flex-direction: column;
    }
}
</style>
<template>
    
    <div class="tarifas_main_container">
        <div class="bg-img-tarifas" >
              <p style="padding: 0 !important; margin: 0;" class="title text-white div-plan" data-aos="fade-up">{{textos.precios_titulo}}</p>
            </div>

            <div class="main_container_tarifas">
                <div class="titulos_tarifas mr-5">
                    <h3>{{textos.precio_libere_petencial }}</h3>
                    <p style="text-align: justify;">{{ textos.precio_parrafo }}</p>
                </div>
                
                <div class="form_container">
                <h2>{{ textos.precio_form_titulo}}</h2>
                <span>{{textos.precio_form_parrafo }}</span>

                <form class="form_container form_form">
                     <div class="autocomplete" >
                         <input :disabled="loadingTarifa" autocomplete="off" type="text" class="form-control" id="myInput" name="place" v-model="ciudadesInput" :placeholder="textos.precio_form_input_placeholder" @keydown.delete="crearWatcher"/>
                         <span style="display: none;" class="text-danger input_vacio">{{textos.precio_form_error_input }}</span>
                    </div>   
                    <div id="autocomplete-list">

                    </div>

                    <select :disabled="loadingTarifa" class="form-control" name="bedrooms" required="" v-model="camasForm">
                        <option disabled value="">{{textos.precio_form_select_placeholder}}</option>
                        <option value="0">{{textos.select_studio }}</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5+</option>
                    </select>
                    <span style="display: none;" class="text-danger select_vacio">{{ textos.precio_form_error_select }}</span>

                    <button :disabled="loadingTarifa" @click="obtenerTarifa" class="btn btn-primary">{{ textos.precio_form_boton }}</button>
                </form>
          <div v-if="loadingTarifa" style="width: 100%;
                display: flex;
                height: 50px;
                position: absolute;
                bottom: 0;
                justify-content: center;
                align-items: center;"  class="load-4">
              <div style="height: 32px; width: 32px;" class="ring-1"></div>
            </div>
            
                <div v-if="datosObtenidos.ok">
                    <h4 class="texto_precios_estimado d-flex flex-column">{{textos.precio_estimado }}: <strong style="color:#007bff ;">{{ precioSinSigno }}</strong></h4>
                </div>
                <div v-if="datosObtenidos.ok == false">
                    <h4>Something went wrong</h4><br>
                    <p>Try again.</p>
                </div>
            </div>
            
            </div>
        
    </div>
</template>

<script>

const NOMINATIM_URL = "https://nominatim.openstreetmap.org/search";


export default {
    data() {
        return {
            textos:JSON.parse(localStorage.getItem('text')),
            loadingTarifa:false,
            TARIFA_URL: `${this.$store.state.urlClients}obtenertarifa`,
            // TARIFA_URL:'http://localhost:2727/obtenertarifa',
            // TARIFA_URL:'https://api.calculator.guestready.com/api/v1/leads/estimate',
            ciudades: [],
            ciudadesInput: "",
            camasForm:"",
            currentFocus: -1,
            currentLang :'',
            timeoutId:'',
            codeLenguaje:{
                'ESPAÑOL'  :'es',
                'HOLANDES' :'nl',
                'FRANCES'  :'fr',
                'ALEMAN'   :'de',
                'ITALIANO' :'it',
                'PORTUGUES':'pt',
                'RUSO'     :'ru',
                'INGLES'   :'en'
            },
            datosObtenidos:{},
            unwatch:null,
            lat:0,
            lon:0,
            precioSinSigno: ''
        };
    },
    mounted(){
       
        this.currentLang = this.codeLenguaje[JSON.parse(localStorage.getItem('text')).idioma.trim()]
        let mainContainer = document.querySelector('.tarifas_main_container')
        mainContainer.addEventListener('click', ()=>{
        let autoCompleteContainer = document.querySelector('#autocomplete-list')

            autoCompleteContainer.style.display = 'none'
        })
        this.crearWatcher()

        window.addEventListener('localStorageChanged', (event)=>{
            
            this.textos = JSON.parse(event.detail.storage);
            
           this.currentLang = this.codeLenguaje[JSON.parse(localStorage.getItem('text')).idioma.trim()]
           
           
        })
        
    },
    methods: {
        autocomplete() {
            let input = this.ciudadesInput;
            document.getElementById('autocomplete-list').style.display = "block"
            let list = document.getElementById("autocomplete-list");
          
            if (!input) {
                list.innerHTML = "";
                return;
            }

           
            fetch(`${NOMINATIM_URL}?q=${input}&accept-language=${this.currentLang}&format=json&limit=3&addressdetails=1`)
                .then((response) => response.json())
                .then((data) => {
                    console.log('AQUI LA RESPUESTA')
                    console.log(data)
                    list.innerHTML = "";
                  
                    for (let i = 0; i < data.length; i++) {
                       
                       let item = document.createElement("DIV");
                       item.classList.add('contender_de_paises')
                   
                        
                        item.innerHTML =
                            "<strong>" +
                            data[i].display_name.substr(0, input.length) +
                            "</strong>";
                        item.innerHTML += data[i].display_name.substr(input.length);
                        item.innerHTML +=
                            "<input type='hidden' value='" + data[i].display_name + "'>";
                        item.addEventListener("click", (e) => {
                     
                            this.lat = data[i].lat
                            this.lon = data[i].lon
                            document.querySelector('#autocomplete-list').style.display = "none"
                           
                            this.ciudadesInput = item.getElementsByTagName("input")[0].value;
                            
                            list.innerHTML = "";
                             this.eliminarWatcher()
                        });
                        list.appendChild(item);
                        this.eliminarWatcher()
                        this.unwatch()
                    }
                })
                .catch((error) => console.error(error));
        },
      async obtenerTarifa(e){
            e.preventDefault()
            
            if(this.ciudadesInput.length===0  || this.camasForm.length === 0){
                if(this.ciudadesInput.length === 0){
                     document.querySelector('.input_vacio').style.display = 'block'
                      document.getElementById('autocomplete-list').style.display = "none"
                }
               if(this.camasForm.length === 0){
                  document.querySelector('.select_vacio').style.display = 'block'
               }
                return
            }
            else{
                 document.querySelector('.input_vacio').style.display = 'none'
                 document.querySelector('.select_vacio').style.display = 'none'     
            }
            this.obtenerTarifa.ok = false
            this.loadingTarifa = true

            let data = {
                "address": this.ciudadesInput,
                "coordinates": {
                    lat: this.lat,
                    lon: this.lon
                },
                "number_of_bedrooms": this.camasForm,
                "context": "https://www.guestready.com/es/obtener-un-presupuesto/",
                "original_source": "https://www.guestready.com/",
                "calculator_path": "GET_A_QUOTE"
            }
                  
            let formFormated = JSON.stringify(data)
            let headers = {
                "Content-Type": "application/json"
            };
            try {
                let fetchService = await fetch(this.TARIFA_URL, {
                    method: 'POST',
                    headers: headers,
                    body: formFormated
                })
                

                let precioTarifa = await fetchService.json()
                console.log('Aqui la respuesta')
                
                this.datosObtenidos = precioTarifa
                this.precioSinSigno = precioTarifa.p.estimate.price.replace(/€/g, "")
                console.log(this.datosObtenidos)
                
            } catch (error) {
                console.log('Ha ocurrido un error')
                console.log(error)
            }finally{
                this.loadingTarifa = false
            }
           
        },
        eliminarWatcher() {
            // eliminamos el watcher cuando queramos
            this.unwatch()
        },
        crearWatcher() {
            document.getElementById('autocomplete-list').style.display = "none"
            // creamos el watcher para ciudadesInput
            this.unwatch = this.$watch('ciudadesInput', (newValue, oldValue) => {
                // hacemos algo con el nuevo valor
               
                if (this.timeoutId) {
                    clearTimeout(this.timeoutId)
                }
                this.timeoutId = setTimeout(this.autocomplete, 600)
            })
        }
    },
    // watch: {
    //     ciudadesInput(newValue, oldValue) {
    //      if(this.timeoutId){
    //         clearTimeout(this.timeoutId)
    //      }
    //      this.timeoutId = setTimeout(this.autocomplete, 600)
         
    //     },
    // },
};
</script>
<!-- Cuerpo del fetch -->
<!-- {
    "address": "Madrid, Comunidad de Madrid, España",
    "coordinates": {
        "lat": 52.55270795,
        "lon": 4.8315779350491805
    },
    "number_of_bedrooms": 2,
    "context": "https://www.guestready.com/es/obtener-un-presupuesto/",
    "original_source": "https://www.guestready.com/",
    "calculator_path": "GET_A_QUOTE"
} -->